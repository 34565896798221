<template>
  <PageWithLayout>
    <!-- <Tab
      :tabData="viewModel.tabData"
      :value.sync="viewModel.tabData.value"/> -->
    <Search
      :tabData="viewModel.tabData"
      :searchDataList.sync="searchData.searchDataList"
      :searchParams.sync="searchData.searchParams"
      @onUpdateTab="viewModel.onUpdateTab()"
      @onSearch="viewModel.onSearch()"/>
    <Board
      :boardData="searchData.boardData"
      :searchParams.sync="searchData.searchParams"
      :dataList="searchData.dataList"
      :paginationData="searchData.paginationData"
      @onClickRow="(rowId,rowData) => viewModel.onClickRow(rowId,rowData)"
      @onSearch="viewModel.onSearch()">
      <template v-slot:headRight>
        <Button
          v-if="viewModel.tabData.value === 'REGERVATION'"
          btnSize="small"
          btnStyle="primary"
          text="발송예약"
          @onClickBtn="viewModel.onClickAdd()">
          <IconSvg iconName="add" :size="12" iconColor="#fff" />
        </Button>
      </template>
    </Board>
    <template v-slot:popup>
      <AlarmRegisterPopup
        v-if="viewModel.isAlarmRegisterPopup"
        @onClickClose="viewModel.onClickCloseAlarmRegisterPopup()"
        @onClickComplete="viewModel.onClickCompleteAlarmRegisterPopup()" />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import AlarmRegisterPopup from '@/views/app/alarm/view/popup/AlarmRegisterPopup';
// viewModel
import AlarmListViewModel from '@/views/app/alarm/viewModel/AlarmListViewModel'

export default {
  name:'AlarmList',
  components:{
    PageWithLayout,
    Search,
    Board,
    Button,
    IconSvg,
    AlarmRegisterPopup
  },
  beforeMount(){
    this.viewModel.init(this.$route.query);
  },
  computed:{
    searchData(){
      return this.viewModel.deployData[this.viewModel.tabData.value];
    },
  },
  data(){
    return{
      viewModel: new AlarmListViewModel(),
    }
  },
}
</script>