import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { convertIdToText } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";

export default class AlarmListViewModel {
  constructor() {
    this.tabData = {
      dataList: [
        { id: 'REGERVATION', text:'알림예약' },
        { id: 'COMPLETE', text:'발송완료' },
      ],
      option:{
        isCount: false,
        queryKeyName:'status'
      },
      value:'REGERVATION'
    }
    this.deployData = {
      REGERVATION:{
        searched: false,
        searchedValue:'',
        hasData: true,
        searchDataList :[
          {
            title:'푸시본문 키워드',
            type: 'Input',
            grid: 100,
            isFull:true,
            value:'',
          },
        ],
        dataList: [],
        boardData : {
          title:'알림예약목록',
          drawDataList: [
            {
              title:'ID',
              width:'60px',
              value:'id',
            },
            {
              title:'상위ID',
              width:'60px',
              value:'parentId',
            },
            {
              title:'발송 스케쥴<br>분할 유저ID',
              width:'130px',
              value:'',
              valueCustom:[
                {
                  type: 'value',
                  value:'startRange',
                },
                { 
                  type: 'text',
                  value: ' ~ '
                },
                {
                  type: 'value',
                  value:'endRange',
                },
              ],
            },
            {
              title:'플랫폼',
              width:'70px',
              value:'platform',
            },
            {
              title:'회원구분',
              width:'70px',
              value:'target',
              filter:{
                name:'convertIdToText',
                value:'user_target'
              },
              class: { classColorFilter:{ name:'convertIdToColor', dataListName:'user_target', prev:'fw_bold tc' } }
            },
            {
              title:'알림<br>유무',
              width:'50px',
              value:'noti.usable',
              filter:{
                name:'convertIdToText',
                value:'common_boolean'
              },
              class: { classColorFilter:{ name:'convertIdToColor', dataListName:'common_boolean', prev:'fw_bold tc' } }
            },
            {
              title:'알림본문',
              width:'',
              value:'noti.contents',
              isAlignLeft: true,
              isEllip: true,
              emptyValueText:'-',
            },
            {
              title:'푸시<br>유무',
              width:'50px',
              value:'push.usable',
              filter:{
                name:'convertIdToText',
                value:'common_boolean'
              },
              class: { classColorFilter:{ name:'convertIdToColor', dataListName:'common_boolean', prev:'fw_bold tc' } }
            },
            {
              title:'푸시본문',
              width:'',
              value:'push.contents',
              isAlignLeft: true,
              isEllip: true,
              emptyValueText:'-',
            },
            {
              title:'예약일시',
              width:'154px',
              value:'sendAt',
              filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm:ss' },
            },
            {
              title:'상태',
              width:'90px',
              value:'status',
              filter:{
                name:'convertIdToText',
                value:'alarm_status'
              },
              badge: {
                badgeColorFilter:{
                  name:'convertIdToColor',
                  dataListName:'alarm_status'
                },
                badgeFill: false
              }
            },
          ],
          option:{
            isTotal: true,
            isSize: true
          }
        },
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      },
      COMPLETE:{
        dataList: [],
        boardData : {
          title:'발송완료목록',
          drawDataList: [
            {
              title:'No.',
              width:'60px',
              value:'id',
            },
            {
              title:'플랫폼',
              width:'70px',
              value:'platform',
              isAlignLeft: true
            },
            {
              title:'회원구분',
              width:'70px',
              value:'target',
              filter:{
                name:'convertIdToText',
                value:'user_target'
              },
              isAlignLeft: true
            },
            {
              title:'본문',
              width:'',
              value:'content',
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'예약일시',
              width:'140px',
              value:'sendDate',
            },
            {
              title:'푸시유무',
              width:'70px',
              value:'push.usable',
              filter:{
                name:'convertIdToText',
                value:'common_boolean'
              },
            },
            {
              title:'푸시오픈/도달/발송',
              width:'180px',
              value:'status',
              valueCustom:[
                { 
                  type: 'text',
                  value: '<em class="tc_primary">'
                },
                {
                  type: 'value',
                  value:'pushOpen',
                },
                { 
                  type: 'text',
                  value: '</em>/'
                },
                {
                  type: 'value',
                  value:'pushReach',
                },
                { 
                  type: 'text',
                  value: '/'
                },
                {
                  type: 'value',
                  value:'pushSendTotal',
                },
              ],
            },
            {
              title:'푸시오픈율',
              width:'80px',
              value:'openPersent',
            },
          ],
          option:{
            isTotal: true,
            isSize: true
          }
        },
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'DESC'
        },
        paginationData: {
          totalCount:0,
          totalPage:0
        }
      }
    };
    this.checkboxOnlyOne = false;
    this.selectedDataList = [];
    this.isAlarmRegisterPopup = false;
  }
  init(query){
    const { pageSize, page, serachValue0 } = query;
    if(pageSize) {
      this.deployData[this.tabData.value].searchParams.pageSize = Number(pageSize);
    }
    if(page) {
      this.deployData[this.tabData.value].searchParams.page = Number(page);
    }
    if(serachValue0) {
      this.deployData[this.tabData.value].searchDataList[0].value = serachValue0;
    }
    this.getData();
  }
  onSearch(){
    this.getData();
  }
  onClickAdd(){
    router.push({ name: 'APP_ALARM_REGISTER' });
  }
  onClickCloseAlarmRegisterPopup(){
    this.isAlarmRegisterPopup = false;
  }
  onClickCompleteAlarmRegisterPopup(){
    this.isAlarmRegisterPopup = false;
  }
  onClickDeploy(){

  }
  onUpdateTab(){}
  onClickRow(rowId, rowData){
    let routerName = ''
    let query = {
      pageSize : this.deployData[this.tabData.value].searchParams.pageSize,
      page : this.deployData[this.tabData.value].searchParams.page,
    }
    if(rowData.status === 'DONE' || rowData.status === 'UNREGISTERED'){
      routerName = 'APP_ALARM_DETAIL';
    }else{
      routerName = 'APP_ALARM_MODIFY';
    }
    const searchValue =this.deployData[this.tabData.value].searchDataList[0].value;
    if(searchValue){
      query.serachValue0 = searchValue;
    }
    router.push({
      name: routerName,
      params: { status : this.tabData.value, id : rowId},
      query : query
    });
  }
  getData(){
    const searchData = this.deployData[this.tabData.value];
    this.deployData[this.tabData.value].searched = false;
    const searchDataList = searchData.searchDataList;
    const isSearchDataList = searchDataList && searchDataList.length > 0;
    let value = '';
    const query = makeQueryStringByObject(searchData.searchParams);
    let path = `${apiPath.ALARM_LIST}${query}`;
    if(isSearchDataList){
      value = searchData.searchDataList[0].value;
      this.deployData[this.tabData.value].searchedValue = value;
      if(value){
        path = `${apiPath.ALARM_KEYWORD_SEARCH.format(value)}${query}`;
      }
    }
    GET_REQUEST(path).then(
    (success) => {
      this.deployData[this.tabData.value].searched = true;
      const resultData = success.data;
      this.deployData[this.tabData.value].dataList = resultData.data;
      this.deployData[this.tabData.value].paginationData.totalCount = resultData.total;
      this.deployData[this.tabData.value].paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}